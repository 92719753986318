import React from 'react';
import styled from 'styled-components';

const Logo = ({ props }) => {
    return (
        <svg id="Layer_1" viewBox="0 0 130.64 85.68" {...props}>
            <polygon
                className="cls-1"
                points="9.66 85.68 16.41 76.82 16.41 85.68 19.25 85.68 19.25 73.1 30.07 58.91 30.07 85.68 32.9 85.68 32.9 55.19 43.73 40.99 43.73 85.68 46.56 85.68 46.56 37.27 57.38 23.08 57.38 85.68 60.22 85.68 60.22 19.36 65.32 12.67 71.04 20.17 71.04 85.68 73.88 85.68 73.88 23.89 84.7 38.08 84.7 85.68 87.53 85.68 87.53 41.8 98.36 56 98.36 85.68 101.19 85.68 101.19 59.72 112.01 73.91 112.01 85.68 114.85 85.68 114.85 77.63 120.99 85.68 130.64 85.68 65.32 0 0 85.68 9.66 85.68"
                fill="#eae9e9"
            />
        </svg>
    );
};

export default Logo;
