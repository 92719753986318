import React, { useEffect } from 'react';
import styled from 'styled-components';
import Routes from './Routes';
import MasterLayout from './components/layout/MasterLayout';
import { store } from './redux';
import { Provider } from 'react-redux';
import { dispatch } from './redux';

const App = () => {
    return (
        <Provider store={store}>
            <MasterLayout>
                <Routes />
            </MasterLayout>
        </Provider>
    );
};

export default App;
