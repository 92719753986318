import React from 'react';
import styled from 'styled-components';
import ModuleList from '../components/modules/ModuleList';
import useSlug from '../hooks/useSlug';
import { connect } from 'react-redux';
import ImageAndTextBox from '../components/modules/ImageAndTextBox';
import imageBuilder from '../utils/imageBuilder';
import VenueDetails from '../components/modules/VenueDetails';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 100px 60px 0;
    align-items: center;

    @media (max-width: 768px) {
        padding: 50px 20px 0;
    }

    .details-wrap {
        position: relative;
        z-index: 999;
        width: 100%;
        max-width: 1400px;
        align-items: flex-start;
    }
`;

const TopText = styled.div`
    white-space: pre-wrap;
    h1 {
        margin-bottom: 0;
    }

    p:first-of-type {
        margin-top: 0;
    }
`;

const Venue = ({ slug, content }) => {
    useSlug('venue/' + slug);

    console.log('CONTENT: ', content);

    if (!content) return null;
    return (
        <Container>
            <ImageAndTextBox
                text={
                    <TopText>
                        <h1>{content.title}</h1>
                        <p>{content.description}</p>
                    </TopText>
                }
                noTransition
                media_image={
                    content.media &&
                    content.media[0]._type !== 'video' &&
                    imageBuilder(content.media[0])
                        .url()
                        .toString()
                }
                video={
                    content.media &&
                    content.media[0]._type === 'video' &&
                    content.media[0]
                }
            />
            {!!content.details && (
                <div className="details-wrap">
                    <VenueDetails
                        openingTimes={content.details.openingTimes}
                        facebookLink={content.details.facebookLink}
                        instagramLink={content.details.instagramLink}
                        telephone={content.details.telephone}
                        email={content.details.email}
                        ageLimit={content.details.ageLimit}
                        absolute={content.absolute}
                    />
                </div>
            )}

            <ModuleList modules={content.modules} />
        </Container>
    );
};

const mapStateToProps = (state, ownProps) => {
    return Object.assign(
        {},
        {
            content: state.pages['venue/' + ownProps.slug]
        },
        ownProps
    );
};

export default connect(mapStateToProps, null)(Venue);
