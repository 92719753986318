import React from 'react';
import styled from 'styled-components';
import { colors } from '../../const';
import { useInView } from 'react-hook-inview';

const Container = styled.div`
    display: flex;
    flex-direction: row;

    @media (max-width: 768px) {
        flex-direction: column !important;

        .image {
            width: 100% !important;
            margin-left: 0 !important;
            margin-right: 0 !important;
        }
    }

    .column {
        width: calc(50% - 30px);

        &:nth-child(2n + 1) {
            margin-right: 30px;
        }

        &:nth-child(2n + 2) {
            margin-left: 30px;
        }

        @media (max-width: 768px) {
            width: 100%;
        }
    }

    .image img {
        width: 100%;

        @media (max-width: 768px) {
            vertical-align: middle;
        }
    }

    &.reversed {
        flex-direction: row-reverse;

        .column:nth-child(2n + 1) {
            margin-left: 30px;
            margin-right: 0;
        }

        .column:nth-child(2n + 2) {
            margin-right: 30px;
            margin-left: 0;
        }

        .text-wrap .color-box {
            right: auto;
            left: 0;
            text-align: right;
        }

        .text-wrap .quote {
            left: auto;
            right: -14px;
        }
    }

    .text-wrap {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;

        h2 {
            margin-top: 0;
            margin-bottom: 17px;
        }

        .quote {
            font-size: 36px;
            font-weight: 500;
            margin: 0;
            position: relative;
            left: -14px;

            @media (max-width: 768px) {
                font-size: 22px;
                left: auto;
                margin-bottom: 5px;
            }
        }

        .meta {
            margin: 0;
            font-size: 14px;
            @media (max-width: 768px) {
                font-size: 11px;
            }

            a {
                color: inherit !important;
            }

            * {
                margin-top: 0;
                margin-bottom: 0;
            }
        }

        .color-box {
            position: absolute;
            background-color: ${colors.purple};
            padding: 40px;
            width: 60vw;
            right: 0;

            @media (max-width: 768px) {
                position: relative;
                width: 100%;
                padding: 20px;
                text-align: left !important;

                .quote {
                    right: auto !important;
                }
            }

            p:last-child {
                margin-bottom: 0;
            }
        }
    }
`;

const QuoteModule = ({ title, quote, meta, image, reversed }) => {
    const [ref, isVisible] = useInView({ unobserveOnEnter: true });
    return (
        <Container
            className={
                'module module-quote ' +
                (reversed ? 'reversed ' : '') +
                (isVisible ? 'in-view' : '')
            }
            ref={ref}
        >
            <div className="column image">
                <img src={image.url().toString()} />
            </div>
            <div className="column text-wrap">
                <div className="color-box">
                    {!!title && <h2>{title}</h2>}

                    {!!quote && <p className="quote">{quote}</p>}

                    {!!meta && <span className="meta">{meta}</span>}
                </div>
            </div>
        </Container>
    );
};

export default QuoteModule;
