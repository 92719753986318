import React from 'react';
import styled, { css } from 'styled-components';
import { colors } from '../../const';
import { useInView } from 'react-hook-inview';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;

    height: 1px;

    margin-top: ${p => p.space / 2}px;
    margin-bottom: ${p => p.space / 2}px;

    @media (max-width: 1023px) {
        height: 0px !important;
    }
`;

const Spacer = ({ space }) => {
    return <Container space={space}></Container>;
};

export default Spacer;
