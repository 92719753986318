import React from 'react';
import styled from 'styled-components';
import SocialButton from '../buttons/SocialButton';
import RoundBorderButton from '../buttons/RoundBorderButton';
import { formatEventDate, formatEventTime } from '../../utils/formatEventTime';

const Container = styled.div`
    font-weight: 500;
    p {
        margin-bottom: 0;
        margin-top: 0;
    }

    .social-links {
        a {
            margin-bottom: 12px;
        }
    }

    @media (max-width: 768px) {
        margin-bottom: 50px;
    }
`;

const EventSidebar = ({
    startTime,
    endTime,
    ageLimit,
    cover,
    venue,
    facebookLink,
    instagramLink,
    ticketLink,
    ...rest
}) => {
    return (
        <Container className="event-sidebar" {...rest}>
            <h2>Details</h2>
            <p className="time">
                {formatEventDate(startTime)}
                <br />
                {formatEventTime(startTime, endTime)}
            </p>
            <p className="venue">{venue}</p>
            <br />
            <p className="ageLimit">Age limit: {ageLimit}</p>
            <p className="cover">Cover: {cover}</p>
            <br />
            <div className="social-links">
                {!!facebookLink && (
                    <SocialButton type="facebook" link={facebookLink} />
                )}

                {!!instagramLink && (
                    <SocialButton type="Instagram" link={instagramLink} />
                )}
            </div>

            <br />

            {!!ticketLink && (
                <div className="buy-ticket">
                    <RoundBorderButton target="_blank" link={ticketLink}>
                        Tickets
                    </RoundBorderButton>
                    <br />
                    <br />
                    <p></p>
                </div>
            )}
        </Container>
    );
};

export default EventSidebar;
