import React, { useState, useEffect } from 'react';

export default function useWindowSize(targetWidth) {
    const [isTargetWidth, setIsTargetWidth] = useState(false);

    const handleResize = () => {
        if (window.innerWidth <= targetWidth && !isTargetWidth) {
            setIsTargetWidth(true);
        } else if (window.innerWidth >= targetWidth) {
            setIsTargetWidth(false);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return isTargetWidth;
}
