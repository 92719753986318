import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import VideoModule from '../components/modules/VideoModule';
import ImageTextModule from '../components/modules/ImageTextModule';
import Api from '../services/Api';

const Container = styled.div`
    padding-left: 60px;
    padding-right: 60px;
`;

const Home = () => {
    const [content, setContent] = useState({});

    useEffect(() => {
        const api = new Api();
        api.getPages().then(res => {
            console.log('get pages', res);
        });
    }, []);

    return (
        <Container>
            <VideoModule />
            <ImageTextModule />
        </Container>
    );
};

export default Home;
