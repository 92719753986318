import React from 'react';
import styled from 'styled-components';
import { useInView } from 'react-hook-inview';

const Container = styled.div`
    width: 100%;
    position: relative;
    margin-bottom: 50px;

    img {
        width: 100%;
    }

    h1,
    h2 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 114px;
        font-weight: 700;
        margin: 0;

        @media (max-width: 768px) {
            font-size: 30px;
        }
    }
`;

const LargeImageWithText = ({ image, text }) => {
    const [ref, isVisible] = useInView({ unobserveOnEnter: true });
    return (
        <Container
            className={
                'module module-large-image-text ' + (isVisible ? 'in-view' : '')
            }
            ref={ref}
        >
            <img src={image} />
            <h2>{text}</h2>
        </Container>
    );
};

export default LargeImageWithText;
