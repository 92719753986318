import React from 'react';
import styled from 'styled-components';
import { colors } from '../../const';
import VenueIllustration from '../VenueIllustration';
import ArrowLink from '../ArrowLink';
import { A } from 'hookrouter';
import { useInView } from 'react-hook-inview';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    .illustration-wrap {
        width: 30%;

        img {
            width: 100%;
        }
    }

    .venues {
        width: 60%;
    }

    .venue {
        margin-bottom: 50px;

        h2 {
            color: ${colors.pink}
            margin-top: 0;
            margin-bottom: 0;
        }
        p {
            white-space: pre-wrap;
            margin-top: 0;
            margin-bottom: 0;
        }

        a {
            text-decoration: none;
            font-weight: 500;
            margin-top: 10px;
            display: block;

            svg {
                margin-left: 5px;
            }
        }
    }

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;

        .illustration-wrap {
            width: 80%;
            margin-bottom: 40px;
        }

        .venues {
            width: 100%;
        }
    }
`;

const VenueList = ({ venues }) => {
    const [ref, isVisible] = useInView({ unobserveOnEnter: true });
    return (
        <Container
            className={
                'module module-venue-list ' + (isVisible ? 'in-view' : '')
            }
            ref={ref}
        >
            <div className="illustration-wrap">
                <img src="/VenueIllustration.svg" />
            </div>
            <div className="venues">
                {!!venues &&
                    venues.map(venue => (
                        <div className="venue" key={venue._key}>
                            {!!venue.title && <h2>{venue.title}</h2>}
                            {!!venue.text && <p>{venue.text}</p>}
                            {!!venue.link && (
                                <ArrowLink
                                    href={`/venue/${venue.link.slug}`}
                                    key={venue.link.title}
                                >
                                    {venue.link.title}
                                </ArrowLink>
                            )}
                        </div>
                    ))}
            </div>
        </Container>
    );
};

export default VenueList;
