import React from 'react';
import styled from 'styled-components';
import BlockContent from '@sanity/block-content-to-react';
import { useInView } from 'react-hook-inview';
import { colors } from '../../const';

const Container = styled.div`
    display: flex;
    min-width: 100%;
    flex-direction: row;
    margin-left: -40px;
    margin-right: -40px;

    @media (max-width: 768px) {
        margin-left: -20px;
        margin-right: -20px;
    }

    .column {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 0 40px;
        @media (max-width: 768px) {
            padding: 0 20px;
        }

        h3,
        h4 {
            font-size: 18px;
            font-weight: 700;
        }

        h4 {
            margin-bottom: 0;
        }

        p {
            font-size: 14px;
            margin-top: 0;
        }

        a {
            color: ${colors.pink};

            &:visited {
                color: ${colors.pink};
            }
        }
    }
`;

const Columns = ({ columns }) => {
    const [ref, isVisible] = useInView({ unobserveOnEnter: true });
    return (
        <Container
            className={'module module-columns ' + (isVisible ? 'in-view' : '')}
            ref={ref}
        >
            {!!(columns && columns.length) &&
                columns.map(item => (
                    <div className="column" key={item._key}>
                        <BlockContent key={item._key} blocks={item.content} />
                    </div>
                ))}
        </Container>
    );
};

export default Columns;
