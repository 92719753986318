import React from 'react';
import styled from 'styled-components';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { colors } from '../../../const';
import pluralHelper from '../../../utils/pluralHelper';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 36px;
    font-weight: 700;
    align-self: stretch;

    @media (max-width: 768px) {
        display: block;
    }

    .progress-bar-wrap {
        width: 120px;
        height: 120px;
        margin-left: 20px;
        margin-right: 20px;
        position: relative;

        @media (max-width: 768px) {
            display: inline-block;
            width: 80px;
            height: 80px;
        }

        .CircularProgressbar {
            z-index: 2;
            position: relative;
        }

        &:before {
            content: ' ';
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background-color: transparent;
            top: 0;
            left: 0;
            box-shadow: 0 0 14px rgba(255, 128, 166, 0.4);
            z-index: 1;
        }

        &:after {
            content: ' ';
            display: block;
            position: absolute;
            width: calc(100% - 19px);
            height: calc(100% - 19px);
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%;
            box-shadow: inset 0 0 14px rgba(255, 128, 166, 0.4);
            z-index: 1;

            @media (max-width: 768px) {
                width: calc(100% - 10px);
                height: calc(100% - 10px);
            }
        }
    }

    .left-wrap,
    .right-wrap {
        display: flex;
        flex: 1;
    }

    .left-wrap {
        justify-content: flex-end;
        > div {
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }
    }

    @media (max-width: 768px) {
        .left-wrap,
        .right-wrap {
            display: none;
        }
    }

    .mobile-text {
        @media (min-width: 769px) {
            display: none;
        }
        display: inline-block;

        font-size: 22px;
    }
`;

const EventListHeader = ({ target, completed, upcoming }) => {
    return (
        <Container>
            <div className="left-wrap">
                <div
                    onClick={() =>
                        target.current.scrollIntoView({ behavior: 'smooth' })
                    }
                >
                    {completed} {pluralHelper(completed, 'event', 'events')}{' '}
                    finished
                </div>
            </div>

            <div className="progress-bar-wrap">
                <CircularProgressbar
                    value={33 /*(100/(upcoming + completed) / completed)*/}
                    text={upcoming || '0'}
                    styles={buildStyles({
                        pathColor: colors.neonPink,
                        trailColor: colors.grey,
                        textSize: '54px',
                        textColor: 'white'
                    })}
                />
            </div>
            <div className="right-wrap">
                <div>{pluralHelper(upcoming, 'event', 'events')} remaining</div>
            </div>
            <div className="mobile-text">
                <div>
                    {completed} {pluralHelper(completed, 'event', 'events')}{' '}
                    finished
                </div>
                <div>{pluralHelper(upcoming, 'event', 'events')} remaining</div>
            </div>
        </Container>
    );
};

export default EventListHeader;
