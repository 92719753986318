import React, { useState, useEffect } from 'react';
import { getOrientation } from '../utils/getOrientation';

export default function useOrientationChange() {
    const [orientation, setOrientation] = useState('vertical');

    const handleOrientationChange = () => {
        if (getOrientation() === 'landscape') {
            setOrientation('landscape');
        } else {
            setOrientation('vertical');
        }
    };

    useEffect(() => {
        window.addEventListener('orientationchange', handleOrientationChange);
        handleOrientationChange();
        return () =>
            window.removeEventListener(
                'orientationchange',
                handleOrientationChange
            );
    }, []);

    return orientation;
}
