import React from 'react';
import styled from 'styled-components';
import { colors } from '../../const';
import SocialButton from '../buttons/SocialButton';
import { connect } from 'react-redux';
import { navigate } from 'hookrouter';
import { useInView } from 'react-hook-inview';

const Container = styled.div`
    padding: 70px 80px;
    margin-top: 50px;
    background-color: ${colors.purple};
    display: flex;
    flex-direction: row;
    width: 100%;

    @media (max-width: 1250px) {
        padding: 70px 60px;
    }

    @media (max-width: 1023px) {
        padding: 36px 30px;
        flex-direction: column;
        padding-bottom: 0;
    }
    .logosWrapper {
        display: flex;
        flex-direction: column;
    }
    .logo {
        width: 212px;
        margin-right: 60px;
        object-fit: contain;

        @media (max-width: 1250px) {
            width: 121px;
            margin-right: 30px;
        }

        @media (max-width: 1023px) {
            width: 127px;
            margin-right: 0;
        }
    }
    .logo2 {
        width: 162px;
        margin-right: 60px;
        object-fit: contain;

        @media (max-width: 1250px) {
            width: 121px;
            margin-right: 30px;
        }

        @media (max-width: 1023px) {
            width: 127px;
            margin-right: 0;
        }
    }
    .menu {
        flex: 1;
        display: flex;
        flex-direction: row;

        @media (max-width: 1023px) {
            flex-wrap: wrap;
            margin-top: 40px;
        }

        @media (max-width: 600px) {
            flex-direction: column;
        }

        .column {
            display: flex;
            flex: 1;
            flex-direction: column;
            padding-left: 16px;

            @media (max-width: 1023px) {
                margin-bottom: 30px;
            }

            .title {
                font-weight: 700;
                margin-bottom: 18px;
                font-size: 16px;
            }

            a {
                font-size: 15px;
                display: block;
                text-decoration: none;
                color: ${colors.pink};
                font-weight: 500;
                margin-bottom: 5px;
            }

            .social-button {
                display: flex;
                margin-bottom: 15px;
                svg {
                    height: 35px;
                    width: 35px;
                    margin-right: 5px;
                }
            }
        }
    }

    .address {
        white-space: pre-wrap;
    }
`;

const MenuItem = ({ item }) => {
    if (item.internalLink) {
        let link =
            '/' +
            (item.internalLink._type !== 'page'
                ? item.internalLink._type + '/'
                : '') +
            item.internalLink.slug;
        if (item.internalLinkAnchor) link += '#' + item.internalLinkAnchor;

        return (
            <a
                href={link}
                onClick={e => {
                    e.preventDefault();
                    navigate(link);
                }}
            >
                {item.title}
            </a>
        );
    } else {
        return (
            <a href={item.externalLink} target="_blank">
                {item.title}
            </a>
        );
    }
};

const Footer = ({ columns, contact }) => {
    return (
        <Container>
            <div className="logosWrapper">
                <a
                    href="/"
                    onClick={e => {
                        e.preventDefault();
                        navigate('/');
                    }}
                >
                    <img
                        src="/stavkroa-hemsedal-logo.png"
                        alt="stavkroa hemsedal logo"
                        className="logo"
                    />
                </a>
                <a href="https://poex.no/">
                    <img
                        src="/PoEx-Company-Black.png"
                        alt="PoEx Company logo"
                        className="logo2"
                    />
                </a>
            </div>
            <div className="menu">
                {columns &&
                    columns.map(column => (
                        <div className="column" key={column._key}>
                            <div className="title">{column.title}</div>
                            {!!column.items &&
                                column.items.map(item => (
                                    <MenuItem item={item} key={item._key} />
                                ))}
                        </div>
                    ))}
                <div className="column">
                    <div className="title">Contact</div>
                    {!!contact.email && (
                        <a href={`mailto:${contact.email}`}>{contact.email}</a>
                    )}
                    {!!contact.mapLink && (
                        <a href={contact.mapLink} target="_blank">
                            Get directions
                        </a>
                    )}
                    {!!contact.telephone && <div>{contact.telephone}</div>}
                    {!!contact.address && (
                        <div className="address">
                            <br />
                            {contact.address}
                        </div>
                    )}
                </div>
                <div className="column">
                    <div className="title">Follow us on</div>
                    {!!contact.facebook && (
                        <SocialButton type="facebook" link={contact.facebook} />
                    )}
                    {!!contact.instagram && (
                        <SocialButton
                            type="instagram"
                            link={contact.instagram}
                        />
                    )}
                    {!!contact.youtube && (
                        <SocialButton type="youtube" link={contact.youtube} />
                    )}
                    {!!contact.linkedin && (
                        <SocialButton type="linkedin" link={contact.linkedin} />
                    )}
                </div>
            </div>
        </Container>
    );
};

const mapStateToProps = (state, ownProps) => {
    return Object.assign(
        {},
        {
            columns: state.global.footer.menu || [],
            contact: state.global.contact
        },
        ownProps
    );
};

export default connect(mapStateToProps, null)(Footer);
