const defaultState = { isOpen: false };

export default (state = defaultState, action) => {
    const { type, payload } = action;

    switch (type) {
        case 'header/menu/open':
            return { ...state, isOpen: true };
        case 'header/menu/close':
            return { ...state, isOpen: false };
        case 'header/menu/toggle':
            return { ...state, isOpen: !state.isOpen };
        case 'REDUX/CLEAR':
            return defaultState;
        default:
            return state;
    }
};
