import React from 'react';
import styled from 'styled-components';
import ArrowLink from '../ArrowLink';
import { colors } from '../../const';
import getFullSlug from '../../utils/getFullSlug';
import ReactPlayer from 'react-player';
import { useInView } from 'react-hook-inview';

const Container = styled.div`
    display: flex;
    flex-direction: row;

    @media (max-width: 768px) {
        flex-direction: column !important;
    }

    .column {
        width: calc(50% - 30px);

        &:nth-child(2n + 1) {
            margin-right: 30px;
        }

        &:nth-child(2n + 2) {
            margin-left: 30px;
        }

        @media (max-width: 768px) {
            width: 100%;
            margin-left: 0 !important;
            margin-right: 0 !important;
            margin-bottom: 30px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .text-wrap {
        display: flex;
        justify-content: center;
        flex-direction: column;

        h2 {
            margin-top: 0;

            @media (max-width: 768px) {
                margin-bottom: 0;
            }
        }

        p {
            font-size: 30px;

            @media (max-width: 768px) {
                font-size: 14px;
            }
        }

        .link {
        }
    }

    &.mirrored {
        flex-direction: row-reverse;

        .column:nth-child(2n + 1) {
            margin-left: 30px;
            margin-right: 0;
        }

        .column:nth-child(2n + 2) {
            margin-right: 30px;
            margin-left: 0;
        }

        .image-wrap.dual-image {
            img:first-child {
                right: 0;
                left: auto;
                object-position: 100% 100%;
            }

            img:nth-child(2) {
                left: 0;
                right: auto;
                object-position: 0 0;
            }
        }
    }

    .image-wrap {
        min-height: 30vw;

        @media (max-width: 768px) {
            min-height: 0;
        }
    }

    &.has-video .image-wrap {
        min-height: 0;
    }

    .image-wrap.single-image {
        img {
            width: 100%;
            height: auto;
        }
    }

    .image-wrap.dual-image {
        position: relative;
        img {
            position: absolute;
            width: 100%;
            height: auto;

            object-fit: contain;

            &:first-child {
                max-width: 60%;
                max-height: 60%;
                left: 0;
                top: 0;
                object-position: 0 0;
            }

            &:nth-child(2) {
                max-width: 80%;
                max-height: 80%;
                right: 0;
                bottom: 0;
                object-position: 100% 100%;
            }
        }

        @media (max-width: 768px) {
            img {
                position: relative;
                &:first-child {
                    max-width: none;
                    width: 50%;
                    position: absolute;
                    z-index: 1;
                    left: 0 !important;
                    right: auto !important;
                    object-position: 0 0 !important;
                }

                &:nth-child(2) {
                    width: 90%;
                    max-width: none;
                    margin-left: 10%;
                    margin-top: 60px;
                }
            }
        }
    }
`;

const ImageTextModule = ({ title, text, video, link, images, mirrored }) => {
    const [ref, isVisible] = useInView({ unobserveOnEnter: true });

    return (
        <Container
            className={
                'module module-image-and-text  ' +
                (mirrored ? 'mirrored ' : '') +
                (video ? 'has-video ' : '') +
                (isVisible ? 'in-view' : '')
            }
            ref={ref}
        >
            <div
                className={
                    'image-wrap column ' +
                    (images && images.length === 2
                        ? 'dual-image'
                        : 'single-image')
                }
            >
                {!!video && (
                    <ReactPlayer
                        className="player-wrap"
                        url={video}
                        width="100%"
                    />
                )}
                {!!(!video && images) &&
                    images.map(item => (
                        <img
                            src={item.url().toString()}
                            key={item.url().toString()}
                        />
                    ))}
            </div>
            <div className="text-wrap column">
                <div className="text-inner-wrap">
                    <h2>{title}</h2>
                    {text}
                    {!!(link && link.length) &&
                        link.map((item, key) => {
                            return (
                                <ArrowLink
                                    external={item._type === 'externalLink'}
                                    href={getFullSlug(item)}
                                    key={key}
                                >
                                    {item.title}
                                </ArrowLink>
                            );
                        })}
                </div>
            </div>
        </Container>
    );
};

export default ImageTextModule;
