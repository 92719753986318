const randomString = length => {
    const possibleChars =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let text = [];

    for (let i = 0; i < length; i++) {
        text.push(
            possibleChars.charAt(
                Math.floor(Math.random() * possibleChars.length)
            )
        );
    }

    return text.join('');
};

export default randomString;
