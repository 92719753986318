import React from 'react';
import styled from 'styled-components';
import randomString from '../../utils/randomString';

const FacebookIcon = styled(props => {
    const id = randomString(4);
    return (
        <svg
            width="23.254"
            height="23.254"
            viewBox="0 0 23.254 23.254"
            {...props}
        >
            <defs>
                <clipPath id={id}>
                    <path
                        d="M0-36.373A11.627,11.627,0,0,1,11.627-48,11.627,11.627,0,0,1,23.254-36.373,11.627,11.627,0,0,1,11.627-24.746,11.627,11.627,0,0,1,0-36.373Zm12.839,6.836v-6.326h1.746l.231-2.18H12.839l0-1.091c0-.569.054-.873.871-.873H14.8v-2.18H13.058c-2.1,0-2.836,1.057-2.836,2.836v1.309H8.914v2.18h1.308v6.325Z"
                        transform="translate(0 48)"
                        fill="#fff"
                        clipRule="evenodd"
                    />
                </clipPath>
            </defs>
            <g transform="translate(0 0)" clipPath={`url(#${id})`}>
                <path
                    d="M-5-53H23.1v28.1H-5Z"
                    transform="translate(2.578 50.578)"
                    fill="#fff"
                />
            </g>
        </svg>
    );
})``;

export default FacebookIcon;
