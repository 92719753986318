import React from 'react';
import styled from 'styled-components';
import Header from './Header';
import Footer from './Footer';

const Container = styled.div`
    background-color: black;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
`;

const PageContent = styled.div`
    display: flex;
    flex: 1;
    min-height: 100vh;
    width: 100%;
    align-items: center;
    //max-width: 1400px;
`;

const MasterLayout = ({ children }) => {
    return (
        <Container>
            <Header />
            <PageContent>{children}</PageContent>
            <Footer />
        </Container>
    );
};

export default MasterLayout;
