import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { A } from 'hookrouter';
import { connect } from 'react-redux';
import getFullSlug from '../../utils/getFullSlug';
import Logo from '../Logo';
import SocialButton from '../buttons/SocialButton';
import useWindowSize from '../../hooks/useWindowSize';
import { dispatch } from '../../redux';
import { navigate } from 'hookrouter';
import moment from 'moment';

const Container = styled.header`
    width: 100%;
    position: sticky;
    top: -15px;
    z-index: 5555;
    @media (max-width: 1023px) {
        top: 0px;
    }
    .headerWrap {
        background-color: black;
        display: flex;
        flex-direction: row;
        position: relative;
        width: 100%;
        height: 85px;
        align-items: flex-end;
        z-index: 999;
        padding-left: 30px;
        padding-right: 30px;
        justify-content: space-between;

        @media (max-width: 1023px) {
            height: 55px;
            padding-left: 0;
            padding-right: 0;
        }
    }

    .logo {
        display: block;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        svg {
            width: auto;
            height: 52px;
        }

        @media (max-width: 1023px) {
            svg {
                height: 40px;
            }
        }
    }

    .header-content {
        padding-left: 100px;
        padding-right: 100px;
        padding-top: 45px;
        padding-bottom: 45px;
        background-color: black;
        position: absolute;
        opacity: 0;
        top: 85;
        left: 0;
        overflow: auto;
        width: 100%;
        display: flex;
        transform: translateY(-200px);
        flex-direction: row;
        justify-content: space-between;

        .mobile-menu {
            display: none;
        }

        @media (max-width: 1023px) {
            padding-left: 35px;
            padding-right: 35px;
            flex-direction: column;
            display: none;

            &.open {
                position: absolute;
                top: 55;
                display: block;
                padding-bottom: 50vh;
                height: 100vh;
                width: 100%;
                background-color: black;
            }

            .mobile-menu {
                display: block;
                margin-bottom: 20px;

                a {
                    font-size: 16px;
                    padding: 1em;
                    margin: -1.2em;
                }
            }

            .social-links {
                margin-top: 60px;
            }
        }

        &.open {
            opacity: 1;
            visibility: visible;
            overflow: auto;
            transform: translateY(0px);
            transition: visibility 0s, transform 0.8s ease, opacity 0.6s ease;
        }

        a {
            display: block;
            text-decoration: none;
            color: white;
            font-size: 30px;
            font-weight: 700;
            text-transform: uppercase;
        }
    }
`;

const Menu = styled.nav`
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    align-items: center;

    @media (max-width: 1023px) {
        margin-bottom: 0;
        > * {
            &.opening-hours {
                margin-bottom: 9px;
            }
        }
    }

    a {
        color: white;
        text-decoration: none;
        font-size: 16px;
        font-weight: 700;
        span {
            margin-right: 4px;
        }
        @media (max-width: 1023px) {
            &.hidden-mobile {
                display: none;
            }
        }
        &:hover {
            color: #ff007d;
        }
    }

    > * {
        &.opening-hours {
            padding-right: 10px;
            color: white;
            text-decoration: none;
            font-size: 16px;
            font-weight: 700;

            @media (max-width: 1023px) {
                &.hidden-mobile {
                    display: none;
                }
            }

            span {
                margin-right: 4px;
            }
        }
        padding-left: 24px;
        padding-right: 24px;
    }
`;

const Hamburger = styled(props => (
    <button {...props}>
        <div />
    </button>
))`
    width: 40px;
    height: 40px;
    display: inline-flex;
    padding: 10px;
    background: none;
    box-shadow: none;
    border: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @media (max-width: 1023px) {
        top: -5px;
        position: relative;
    }

    div {
        position: relative;
        z-index: 9999;
        width: 20px;
        height: 3px;
        background-color: white;

        &:before,
        &:after {
            content: ' ';
            width: 20px;
            height: 3px;
            display: block;
            background-color: white;
            position: absolute;
        }

        &:after {
            bottom: -6px;
        }

        &:before {
            top: -6px;
        }
    }

    &.open {
        div {
            width: 20px;
            height: 20px;
            background-color: transparent;

            transform: rotate(45deg);

            &:before {
                top: 50%;
                margin-top: -1px;
                left: 0;
                width: 22px;
            }

            &:after {
                width: 3px;
                height: 22px;
                top: 0;
                left: 50%;
                bottom: auto;
                margin-left: -1px;
            }
        }
    }
`;

const getOpeningState = openingHours => {
    const today = moment();

    const days = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
    ];
    const dager = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    const dayNumber = parseInt(today.format('e'));
    const day = days[today.format('e')];
    const dag = dager[today.format('e')];
    const tomorrowDay = days[parseInt(today.format('e')) + 1];
    let nesteDagClosed = dager[parseInt(today.format('e')) + 1];
    let nesteDagOpen = nesteDagClosed;

    let skew = [...days.slice(dayNumber + 1), ...days.slice(0, dayNumber + 1)];

    let nesteDagSkew = [
        ...dager.slice(dayNumber + 1),
        ...dager.slice(0, dayNumber + 1)
    ];

    let i = 0;
    let nextDay;

    skew.map(day => {
        let openingDay = openingHours.find(x => x.day === day);

        if (!nextDay && openingDay && openingDay.opens) {
            nesteDagOpen = nesteDagSkew[i];
            nextDay = openingDay;
        }
        i++;
    });

    let time = today.format('HH:mm');
    let opening = openingHours.find(x => x.day === day);

    if (!opening) {
        opening = nesteDagOpen;
    }

    let skewedClosingHours = opening.closes;
    if (opening.closes >= '00:00' && opening.closes <= '05:00') {
        skewedClosingHours = `${Number(opening.closes.slice(1, 2)) +
            24}:${opening.closes.slice(3, opening.closes.length)}`;
    }

    if (time >= '00:00' && time <= '05:00') {
        time = `${Number(time.slice(1, 2)) + 24}:${time.slice(3, time.length)}`;
    }

    if (time < opening.opens) {
        return (
            <div className="opening-hours">
                <span>Opens</span>
                <span>{dag}</span>
                {opening.opens}
            </div>
        );
    } else if (time > opening.opens && time < skewedClosingHours) {
        return (
            <div className="opening-hours">
                <span>Closes</span> {opening.closes}
            </div>
        );
    } else if (opening.opens > skewedClosingHours) {
        return (
            <div className="opening-hours">
                <span>Opens</span>
                <span>{dag}</span>
                {opening.opens}
            </div>
        );
    } else {
        return (
            <div className="opening-hours" href="#">
                <span>Opens</span>
                <span>{nesteDagOpen}</span>
                {nextDay.opens}{' '}
            </div>
        );
    }
};

const Header = ({
    menuItems,
    menuRightItems,
    secondaryMenuItems,
    contact,
    isOpen,
    openingHours,
    disableClock
}) => {
    const closeMenu = () => {
        dispatch('header/menu/close');
    };

    const nav = (href, e) => {
        e.preventDefault();
        closeMenu();
        navigate(href);
    };
    const isMobile = useWindowSize(768);

    //     useEffect(() => {
    //         if (isOpen && isMobile) {
    //             document.body.style.overflow = 'hidden';
    //         }
    //         return () => {
    //             document.body.style.overflow = 'unset';
    //         }
    //     })

    return (
        <Container>
            <div className="headerWrap">
                <Menu className="left">
                    <Hamburger
                        onClick={() => dispatch('header/menu/toggle')}
                        className={isOpen ? 'open' : ''}
                    />
                    {menuItems &&
                        menuItems.map(item =>
                            item?.externalLink ? (
                                <a
                                    className="hidden-mobile"
                                    href={item.externalLink}
                                    key={item._key}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={closeMenu}
                                >
                                    {item.title}
                                </a>
                            ) : (
                                <a
                                    className="hidden-mobile"
                                    href={getFullSlug(item.ref)}
                                    key={item._key}
                                >
                                    {item.title}
                                </a>
                            )
                        )}
                </Menu>
                <A href="/" className="logo">
                    <Logo src="/stavkroa-logo.png" />
                </A>
                <Menu className="right">
                    {menuRightItems &&
                        menuRightItems.map(item =>
                            item?.externalLink ? (
                                <A
                                    className="hidden-mobile"
                                    href={item.externalLink}
                                    key={item._key}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={closeMenu}
                                >
                                    {item.title}
                                </A>
                            ) : (
                                <A
                                    className="hidden-mobile"
                                    href={getFullSlug(item.ref)}
                                    key={item._key}
                                >
                                    {item.title}
                                </A>
                            )
                        )}
                    {!disableClock &&
                        openingHours &&
                        getOpeningState(openingHours)}
                </Menu>
            </div>
            <div className={'header-content ' + (isOpen ? 'open' : '')}>
                <div className="mobile-menu">
                    {menuItems &&
                        menuItems.map(item =>
                            item?.externalLink ? (
                                <a
                                    href={item.externalLink}
                                    key={item._key}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={closeMenu}
                                >
                                    {item.title}
                                </a>
                            ) : (
                                <a
                                    href={getFullSlug(item.ref)}
                                    onClick={e => nav(getFullSlug(item.ref), e)}
                                    key={item._key}
                                >
                                    {item.title}
                                </a>
                            )
                        )}
                    {menuRightItems &&
                        menuRightItems.map(item =>
                            item?.externalLink ? (
                                <a
                                    href={item.externalLink}
                                    key={item._key}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={closeMenu}
                                >
                                    {item.title}
                                </a>
                            ) : (
                                <a
                                    href={getFullSlug(item.ref)}
                                    onClick={e => nav(getFullSlug(item.ref), e)}
                                    key={item._key}
                                >
                                    {item.title}
                                </a>
                            )
                        )}
                </div>
                <div className="venues">
                    {secondaryMenuItems &&
                        secondaryMenuItems.map(item => (
                            <a
                                href={getFullSlug(item.ref)}
                                onClick={e => nav(getFullSlug(item.ref), e)}
                                key={item._key}
                            >
                                {item.title}
                            </a>
                        ))}
                </div>
                <div className="social-links">
                    {!!(contact && contact.facebook) && (
                        <SocialButton type="facebook" link={contact.facebook} />
                    )}
                    {!!(contact && contact.instagram) && (
                        <SocialButton
                            type="instagram"
                            link={contact.instagram}
                        />
                    )}
                    {!!(contact && contact.youtube) && (
                        <SocialButton type="youtube" link={contact.youtube} />
                    )}
                    {!!(contact && contact.linkedin) && (
                        <SocialButton type="linkedin" link={contact.linkedin} />
                    )}
                </div>
            </div>
        </Container>
    );
};

const mapStateToProps = (state, ownProps) => {
    return Object.assign(
        {},
        {
            menuItems: state.global.header.menu,
            menuRightItems: state.global.header.menuRight,
            secondaryMenuItems: state.global.header.secondaryMenu,
            contact: state.global.contact,
            isOpen: state.header.isOpen,
            openingHours: state.global.openingHours,
            disableClock: state.global.disableClock
        },
        ownProps
    );
};

export default connect(mapStateToProps, null)(Header);
