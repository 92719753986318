import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Api from '../services/Api';
import EventHero from '../components/modules/EventHero';
import imageBuilder from '../utils/imageBuilder';
import EventSidebar from '../components/modules/EventSidebar';
import BlockContent from '@sanity/block-content-to-react';
import { dispatch } from '../redux';
import { connect } from 'react-redux';
import blockSerializers from '../utils/blockSerializers';

const Container = styled.div`
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    padding-left: 60px;
    padding-right: 60px;
    align-items: center;
    width: 100%;

    @media (max-width: 768px) {
        padding-left: 20px;
        padding-right: 20px;
    }

    .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        max-width: 1400px;

        @media (max-width: 768px) {
            flex-direction: column;

            > * {
                width: 100% !important;
            }
        }

        > {
            &:nth-child(1) {
                width: calc(20% - 30px);
            }

            &:nth-child(2) {
                width: calc(80% - 30px);
            }
        }
    }

    .event-body {
        div {
            h1,
            h2,
            h3,
            h4,
            p {
                @media (min-width: 768px) {
                    width: 100%;
                    max-width: calc(80% - 30px);
                }
            }
        }
        figure {
            margin: 0;
        }
        img {
            width: 100%;
        }
    }
`;

const Event = ({ slug, content }) => {
    useEffect(() => {
        dispatch('page/get-by-slug', `event/${slug}`);
    }, []);

    if (!content) return null;

    return (
        <Container>
            <EventHero
                title={content.title}
                image={content.image && imageBuilder(content.image)}
                media_image={
                    content.media &&
                    content.media[0]._type !== 'video' &&
                    imageBuilder(content.media[0])
                        .url()
                        .toString()
                }
                video={
                    content.media &&
                    content.media[0]._type === 'video' &&
                    content.media[0]
                }
            />
            <div className="row">
                <EventSidebar
                    startTime={content.startTime}
                    endTime={content.endTime}
                    ageLimit={content.details && content.details.ageLimit}
                    cover={content.details && content.details.cover}
                    venue={content.details && content.details.venue}
                    facebookLink={
                        content.details && content.details.facebookLink
                    }
                    instagramLink={
                        content.details && content.details.instagramLink
                    }
                    ticketLink={content.details && content.details.ticketLink}
                />
                <div className="event-body">
                    {!!content.content && (
                        <BlockContent
                            blocks={content.content}
                            projectId={process.env.REACT_APP_SANITY_PROJECT_ID}
                            dataset={process.env.REACT_APP_SANITY_DATASET}
                            serializers={blockSerializers}
                        />
                    )}
                </div>
            </div>
        </Container>
    );
};

const mapStateToProps = (state, ownProps) => {
    return Object.assign(
        {},
        {
            content: state.pages['event/' + ownProps.slug]
        },
        ownProps
    );
};

export default connect(mapStateToProps, null)(Event);
