import React from 'react';
import imageBuilder from './imageBuilder';
import ReactPlayer from 'react-player';
import useWindowSize from '../hooks/useWindowSize';

const WrappedReactPlayer = ({ node }) => {
    const isMobile = useWindowSize(768);
    if (isMobile) {
        return <ReactPlayer height={'360px'} width={'100%'} url={node.url} />;
    }
    return <ReactPlayer url={node.url} />;
};

const blockSerializers = {
    types: {
        image: props => {
            const imageUrl = imageBuilder(props.node)
                .url()
                .toString();
            const image = <img src={imageUrl} />;

            if (!props.node.link) return image;

            return (
                <a
                    href={props.node.link}
                    target="_blank"
                    className="image-link"
                >
                    {image}
                </a>
            );
        },
        youtubeEmbed: ({ node }) => {
            return <WrappedReactPlayer node={node} />;
        }
    }
};

export default blockSerializers;
