import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';
import ReactPlayer from 'react-player';
import { useInView } from 'react-hook-inview';
import useWindowSize from '../../hooks/useWindowSize';
import useOrientationChange from '../../hooks/useOrientationChange';

const Container = styled.div`
    position: relative;
    max-width: none;
    width: calc(100% + 120px);
    margin-top: -125px;
    pointer-events: none;
    &:after {
        content: ' ';
        padding-bottom: 56.25%;
        display: block;
    }

    @media (max-width: 768px) {
        width: calc(100% + 40px);
        &:after {
            content: ' ';
            padding-bottom: 76.87%;
            display: block;
        }
    }

    @media (max-width: 1023px) {
        margin-top: -80px;
    }

    @media (max-width: 623px) {
        margin-top: -40px;
    }

    .player-wrap {
        position: absolute;
        left: 0;
        top: 0;
    }

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;

const VideoModule = ({
    videoLink,
    mobileVideoLink,
    autoPlay,
    pullUp,
    ...rest
}) => {
    const [ref, isVisible] = useInView({ unobserveOnEnter: true });
    const isMobile = useWindowSize(768);
    const orientation = useOrientationChange();

    const renderIFrame = () => {
        if (isMobile && orientation === 'vertical' && mobileVideoLink) {
            return (
                <iframe
                    src={mobileVideoLink + '?background=1'}
                    width="640"
                    height="492"
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                    allowFullScreen
                ></iframe>
            );
        }
        if (videoLink) {
            return (
                <iframe
                    src={videoLink + '?background=1'}
                    width="640"
                    height="360"
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                    allowFullScreen
                ></iframe>
            );
        }
    };

    return (
        <Container
            className={'module module-video ' + (isVisible ? 'in-view' : '')}
            pullUp={pullUp}
            {...rest}
            ref={ref}
        >
            {renderIFrame()}
            {/*
            <video src="https://player.vimeo.com/external/212881482.m3u8?s=51553ade227674948ab38abc21d6fa80d3306724"/>

            <ReactPlayer
                className="player-wrap"
                url="https://youtu.be/Di9kVtL_qco"
                width="100%"
                height="100%"
                controls={false}
                config={{
                    youtube: {
                        playerVars: {
                            //autoplay: 1,
                            controls: 0,
                            modestbranding: 1
                        }
                    }
                }}
            />*/}
        </Container>
    );
};

export default VideoModule;
