import React from 'react';
import styled from 'styled-components';
import randomString from '../../utils/randomString';

const YoutubeIcon = styled(props => {
    const clipId = randomString(4);
    const clipId2 = randomString(4);
    return (
        <svg
            id="Group_23"
            width="34.356"
            height="34.356"
            viewBox="0 0 34.356 34.356"
            {...props}
        >
            <defs>
                <clipPath id={clipId}>
                    <path
                        id="Path_10"
                        d="M148-30.822A17.178,17.178,0,0,1,165.178-48a17.178,17.178,0,0,1,17.178,17.178,17.178,17.178,0,0,1-17.178,17.178A17.178,17.178,0,0,1,148-30.822Zm25.957-4.242a2.326,2.326,0,0,0-1.62-1.663,65.365,65.365,0,0,0-14.317,0,2.326,2.326,0,0,0-1.62,1.663,25.131,25.131,0,0,0-.383,4.528,25.131,25.131,0,0,0,.383,4.528,2.327,2.327,0,0,0,1.62,1.663,53.351,53.351,0,0,0,7.159.393,53.35,53.35,0,0,0,7.159-.393,2.327,2.327,0,0,0,1.62-1.663,25.122,25.122,0,0,0,.383-4.528A25.121,25.121,0,0,0,173.957-35.064Z"
                        transform="translate(-148 48)"
                        fill="#fff"
                        clipRule="evenodd"
                    />
                </clipPath>
                <clipPath id={clipId2}>
                    <path
                        id="Path_12"
                        d="M169.6-21.474V-27.2l4.581,2.863Z"
                        transform="translate(-169.6 27.2)"
                        fill="#fff"
                        clipRule="evenodd"
                    />
                </clipPath>
            </defs>
            <g
                id="Group_5"
                transform="translate(0 0)"
                clipPath={`url(#${clipId})`}
            >
                <path
                    id="Path_9"
                    d="M143-53h41.514v41.514H143Z"
                    transform="translate(-146.579 49.421)"
                    fill="#fff"
                />
            </g>
            <g
                id="Group_6"
                transform="translate(15.46 14.888)"
                clipPath={`url(#${clipId2})`}
            >
                <path
                    id="Path_11"
                    d="M164.6-32.2h11.738v12.884H164.6Z"
                    transform="translate(-168.179 28.621)"
                    fill="#fff"
                />
            </g>
        </svg>
    );
})``;

export default YoutubeIcon;
