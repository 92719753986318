import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    width: 100%;
    position: relative;
    margin-bottom: 50px;

    &.video {
        max-width: 100%;
        width: calc(100% + 120px);
        margin-top: -125px;
        pointer-events: none;
        &:after {
            content: ' ';
            padding-bottom: 56.25vw;
            display: block;
        }
    }

    img {
        width: 100%;
    }

    iframe {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        left: 0;
    }

    h1 {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        text-align: center;
        padding: 0 12px;
        transform: translate(-50%, -50%);
        font-size: 114px;
        font-weight: 700;
        margin: 0;

        @media (max-width: 768px) {
            font-size: 30px;
        }
    }
`;

const EventHero = ({ image, media_image, video, title }) => {
    const renderMedia = () => {
        if (media_image) {
            return <img src={media_image && media_image} />;
        }
        if (video) {
            return (
                <iframe
                    src={video.name + '?background=1'}
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                    allowFullScreen
                ></iframe>
            );
        }
    };

    return (
        <Container className={video ? 'video' : ''}>
            {renderMedia()}
            {/*<img src={image.url().toString()} />*/}
            <h1>{title}</h1>
        </Container>
    );
};

export default EventHero;
