const defaultState = [];

export default (state = defaultState, action) => {
    const { type, payload } = action;

    switch (type) {
        case 'events/set':
            return [...payload];
        case 'REDUX/CLEAR':
            return defaultState;
        default:
            return state;
    }
};
