import React from 'react';
import styled from 'styled-components';

const Container = styled.iframe`
    @media (max-width: 1023px) {
        ${props =>
            props.heightTablet && `height: ${props.heightTablet}px !important`}
    }
    @media (max-width: 719px) {
        ${props =>
            props.heightMobile && `height: ${props.heightMobile}px !important`}
    }
`;

const IframeContainer = ({ url, height, heightTablet, heightMobile }) => {
    return (
        <Container
            width="100%"
            height={height + 'px'}
            heightTablet={heightTablet}
            heightMobile={heightMobile}
            src={url}
            frameBorder="0"
            scrolling="auto"
        ></Container>
    );
};

export default IframeContainer;
