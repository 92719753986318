import React from 'react';
import styled from 'styled-components';
import FacebookIcon from '../icons/FacebookIcon';
import InstagramIcon from '../icons/InstagramIcon';
import YoutubeIcon from '../icons/YoutubeIcon';
import LinkedInIcon from '../icons/LinkedInIcon';
import { colors } from '../../const';

const Container = styled.a`
	span {
	    margin-left: 5px;
	    text-transform: capitalize;
	    color: ${colors.pink}
	    font-size: 15px;
        font-weight: 500;
        vertical-align: super;
	}
	display: flex;
	flex-direction: row;
	align-items: center;
	text-decoration: none !important;

	svg {
        width: 35px;
        height: 35px;
        margin-right: 5px;
    }

	@media (max-width: 768px) {
	    svg {
	        width: 35px;
	        height: 35px;
	    }
	}
`;

const SocialButton = ({ type, link, ...rest }) => {
    return (
        <Container
            href={link}
            target="_blank"
            className="social-button"
            {...rest}
        >
            {type === 'facebook' ? (
                <FacebookIcon />
            ) : type === 'instagram' ? (
                <InstagramIcon />
            ) : type === 'youtube' ? (
                <YoutubeIcon />
            ) : (
                <LinkedInIcon />
            )}
            <span>{type}</span>
        </Container>
    );
};

export default SocialButton;
