const defaultState = {};

export default (state = defaultState, action) => {
    const { type, payload } = action;

    switch (type) {
        case 'page/create':
            return { ...payload };
        case 'page/set':
            return { ...state, [action.payload.slug]: action.payload.data };
        case 'page/update':
            return { ...state, ...payload };
        case 'page/clear':
        case 'REDUX/CLEAR':
            return { ...defaultState };
        default:
            return state;
    }
};
