import React from 'react';
import styled from 'styled-components';
import imageBuilder from '../../../utils/imageBuilder';
import {
    formatEventDate,
    formatEventTime
} from '../../../utils/formatEventTime';
import RoundBorderButton from '../../buttons/RoundBorderButton';
import { colors } from '../../../const';
import { navigate } from 'hookrouter';
import moment from 'moment';

const Container = styled.div`
    padding-bottom: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    h3 {
        font-size: 36px;
        font-weight: 700;
        margin-bottom: 5px;

        @media (max-width: 768px) {
            font-size: 30px;
        }
    }

    &:nth-child(2) {
        margin-top: 100px;
    }

    @media (max-width: 768px) {
        .image-wrap {
            flex-direction: row-reverse !important;
            padding-left: 20px;
        }

        .image-wrap .date div {
            left: -20px !important;
            &:after {
                top: 100%;
                bottom: auto;
            }
        }
    }

    &:nth-child(2n + 2) {
        .image-wrap {
            flex-direction: row-reverse;
        }

        .image-wrap .date div {
            left: 0;
            &:after {
                top: 100%;
                bottom: auto;
            }
        }
    }

    .short-description {
        font-weight: 500;
    }

    .location-and-venue {
        font-size: 14px;
        font-weight: 500;
        margin-top: 10px;
    }

    .image-wrap {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;

        img {
            max-width: 100%;
            max-height: 600px;
            object-fit: contain;
            object-position: 50% 50%;
        }

        iframe {
            pointer-events: none;
            max-width: 100%;
            height: 326px;
            width: 570px;
        }

        .date {
            font-size: 18px;
            font-weight: 700;
            position: relative;
            width: 25px;
            height: 25px;

            div {
                transform: rotate(90deg) translateX(-50%);
                transform-origin: 0 0;
                white-space: nowrap;
                position: absolute;
                left: 50px;
                top: 50%;

                &:after {
                    content: ' ';
                    display: block;
                    position: absolute;
                    height: 500px;
                    background-color: ${colors.greyBorder};
                    width: 1px;
                    right: 50%;
                    bottom: 100%;
                }
            }
        }
    }

    .centered {
        text-align: center;
    }

    .buttons {
        margin-top: 20px;

        > * {
            margin-left: 10px;
            margin-right: 10px;
        }

        @media (max-width: 768px) {
            > * {
                font-size: 8px;
                font-weight: 700;
                text-transform: uppercase;
                padding: 9px 24px;
            }
        }
    }

    .short-description {
        white-space: pre-wrap;
    }
`;

const Event = ({ finished, event }) => {
    const renderMedia = ({ image, video }) => {
        if (image) {
            return <img alt={event.title + ' image'} src={image && image} />;
        }
        if (video) {
            return (
                <iframe
                    src={video.name + '?background=1'}
                    href={`/event/${event.slug.current}`}
                    frameBorder="0"
                    height="100%"
                    width="100%"
                    allow="autoplay; fullscreen"
                    allowFullScreen
                ></iframe>
            );
        }
    };

    return (
        <Container>
            <div className="image-wrap">
                <a
                    href={`/event/${event.slug.current}`}
                    onClick={() => navigate(`/event/${event.slug.current}`)}
                >
                    {renderMedia({
                        image:
                            event.media &&
                            event.media[0]._type !== 'video' &&
                            imageBuilder(event.media[0])
                                .url()
                                .toString(),
                        video:
                            event.media &&
                            event.media[0]._type === 'video' &&
                            event.media[0]
                    })}
                </a>
                <div className="date">
                    <div>{moment(event.startTime).format('MMMM Do')}</div>
                </div>
            </div>
            <div className="centered">
                <h3>{event.title}</h3>
                <span className="short-description">
                    {event.shortDescription}
                </span>
                <div className="location-and-venue">
                    <span>{formatEventDate(event.startTime)}</span>
                    <br />
                    <span>
                        {formatEventTime(event.startTime, event.endTime)},{' '}
                    </span>
                    <span>{event.details.venue}</span>
                </div>
                <div className="buttons">
                    {!finished &&
                        !!(event.details && event.details.ticketLink) && (
                            <RoundBorderButton
                                color={colors.gold}
                                target="_blank"
                                href={event.details.ticketLink}
                            >
                                Tickets
                            </RoundBorderButton>
                        )}
                    <RoundBorderButton
                        color={colors.pink}
                        href={`/event/${event.slug.current}`}
                        onClick={() => navigate(`/event/${event.slug.current}`)}
                    >
                        Read more
                    </RoundBorderButton>
                </div>
            </div>
        </Container>
    );
};

export default Event;
