import React from 'react';
import styled from 'styled-components';
import { colors } from '../../const';
import { useInView } from 'react-hook-inview';

const Container = styled.div`
    width: 100%;
    margin-bottom: 40px;
    .title-wrap {
        width: 100%;
        display: flex;
        flex-direction: row;
        text-align: center;

        &:before,
        &:after {
            content: ' ';
            display: inline-flex;
            align-self: center;
            flex: 1;
            height: 1px;
            background-color: ${colors.greyBorder};
        }

        h2 {
            color: ${colors.pink};
            text-transform: uppercase;
            margin: 0 20px;
        }
    }
    p {
        white-space: pre-wrap;
        text-align: center;
        font-weight: 500;
        margin-top: 0;
    }

    @media (max-width: 768px) {
        margin-top: 0px;
        top: 0px;
        margin-bottom: 20px !important;
    }
`;

const TitleDivider = ({ title, text, ...rest }) => {
    const [ref, isVisible] = useInView({ unobserveOnEnter: true });
    return (
        <Container
            className={
                'module module-title-divider ' + (isVisible ? 'in-view' : '')
            }
            {...rest}
            ref={ref}
        >
            <div className="title-wrap">
                <h2>{title}</h2>
            </div>
            {!!text && <p>{text}</p>}
        </Container>
    );
};

export default TitleDivider;
