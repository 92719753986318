import React from 'react';
import styled, { keyframes } from 'styled-components';
import SmallArrow from './icons/SmallArrow';
import { colors } from '../const';
import { A } from 'hookrouter';

const nudge = keyframes`
    0% {
        transform: rotate(-12deg);
    }

    33% {
        transform: rotate(12deg);
    }

    66% {
        transform: rotate(-12deg);
    }
`;

const External = styled.a`
    color: ${colors.pink};
    cursor: pointer;
    font-weight: 500;
    text-decoration: none;
    &:hover {
        svg {
            transform: translateX(2px);
            animation: ${nudge} 0.4s linear;
        }
    }

    svg {
        margin-left: 8px;
    }
`;
const Container = styled(A)`
    color: ${colors.pink};
    cursor: pointer;
    font-weight: 500;
    text-decoration: none;
    &:hover {
        svg {
            transform: translateX(2px);
            animation: ${nudge} 0.4s linear;
        }
    }

    svg {
        margin-left: 8px;
    }
`;

const ArrowLink = ({ external, href, children }) => {
    if (external)
        return (
            <External target="_blank" href={href}>
                {children}
                <SmallArrow />
            </External>
        );
    return (
        <Container href={href}>
            {children}
            <SmallArrow />
        </Container>
    );
};

export default ArrowLink;
