import React from 'react';
import styled from 'styled-components';
import SocialButton from '../buttons/SocialButton';

const Container = styled.div`
    ${props => props.absolute && 'position: absolute;'}
    font-weight: 500;
    max-width: 20%;
    align-self: flex-start;

    overflow-wrap: break-word;
    @media (max-width: 768px) {
        position: initial;
        max-width: none;
        width: 100%;
        margin-bottom: 0;

        .social-links {
            display: flex;
            flex-direction: row;

            > * {
                margin-right: 20px;
            }
        }
    }

    p {
        margin-bottom: 0;
        margin-top: 0;
    }

    .time {
        white-space: pre-wrap;
    }

    .social-links > * {
        margin-bottom: 10px;
    }
`;

const VenueDetails = ({
    openingTimes,
    ageLimit,
    facebookLink,
    instagramLink,
    telephone,
    email,
    absolute,
    ...rest
}) => {
    return (
        <Container absolute={!!absolute} className="venue-info" {...rest}>
            <h2>Details</h2>
            {!!openingTimes && (
                <>
                    <p>Opening hours:</p>
                    <p className="time">{openingTimes}</p>
                    <br />
                </>
            )}

            {(!!facebookLink || !!instagramLink) && (
                <div className="social-links">
                    {!!facebookLink && (
                        <SocialButton type="facebook" link={facebookLink} />
                    )}

                    {!!instagramLink && (
                        <SocialButton type="Instagram" link={instagramLink} />
                    )}
                </div>
            )}
            <br />
            {(!!telephone || !!email) && (
                <div className="contact-info">
                    {!!telephone && <p>{telephone}</p>}
                    {!!email && <p>{email}</p>}
                </div>
            )}
            <br />
            {!!ageLimit && <p className="ageLimit">Age limit: {ageLimit}</p>}
        </Container>
    );
};

export default VenueDetails;
