const defaultState = {
    header: [],
    footer: [],
    contact: {},
    hasGlobal: false
};

export default (state = defaultState, action) => {
    const { type, payload } = action;

    switch (type) {
        case 'global/set':
            return {
                header: payload.header || [],
                footer: payload.footer || [],
                contact: payload.contact || {},
                hasGlobals: true,
                openingHours: payload.opening_hours || {},
                disableClock: payload.disable_clock || {}
            };

        case 'global/update':
            return { ...state, ...payload };
        case 'global/clear':
        case 'REDUX/CLEAR':
            return { ...defaultState };
        default:
            return state;
    }
};
