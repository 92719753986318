import { combineReducers } from 'redux';
import pages from './reducers/pageReducer';
import global from './reducers/globalReducer';
import events from './reducers/eventsReducer';
import header from './reducers/headerReducer';
//REDUCER_IMPORTS

const reducers = combineReducers({
    pages,
    global,
    events,
    header
    //REDUCER_COMBINES
});

export default reducers;
