import React from 'react';
import styled from 'styled-components';

const SmallArrow = styled(props => (
    <svg width="6.299" height="9.769" viewBox="0 0 6.299 9.769" {...props}>
        <path
            d="M819.6,1500.276l4.177,4.177-4.177,4.177"
            transform="translate(-818.892 -1499.569)"
            fill="none"
            stroke="#ff007d"
            strokeWidth="2"
        />
    </svg>
))`
    path {
        stroke: currentColor !important;
    }
`;

export default SmallArrow;
