import Store from './store';
export { default as store } from './store';

export const dispatch = (type, payload) => {
    if (!payload) payload = {};
    Store.dispatch({
        type,
        payload
    });
};
