import { all } from 'redux-saga/effects';
import exampleSaga from './exampleSaga';
import pageSaga from './pageSaga';
import globalSaga from './globalSaga';
//IMPORT_SAGAS

export default function*() {
    yield all([
        ...exampleSaga,
        ...pageSaga,
        ...globalSaga
        //COMBINE_SAGAS
    ]);
}
