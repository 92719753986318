import client from '../utils/sanityClient';

const globalsQuery = `
    *[_id == 'siteSettings'][0]{ 
        contact,
        footer{
            ..., 
            menu[]{ 
                ..., 
                items[]{
                    ..., 
                    internalLink->{title, "slug":slug.current, _type}
                }
            }
        },
        header{
            ...,
            menu[]{
                ...,
                ref->{title, "slug":slug.current, _type}
            },
            menuRight[]{
                ...,
                ref->{title, "slug":slug.current, _type}  
            },
            secondaryMenu[]{
                ...,
                ref->{title, "slug":slug.current, _type}
            }
        },
        disable_clock,
        opening_hours[]{
            day,
            opens,
            closes
        }
    }
`;

export default class Api {
    getPages = () =>
        new Promise(async (resolve, reject) => {
            try {
                const response = await client.fetch(
                    '*[_type == "page" && site == "stavkroa"]'
                );
            } catch (e) {
                console.log('error', e);
                reject();
            }
        });

    getSlug = (slug, type = 'page', getGlobals, fetchEvents) => {
        return client.fetch(
            `{
                "page": *[slug.current == "${slug}" && _type == "${type}"][0]{
                    ..., 
                    modules[] {
                        ...,  
                        link[] {
                            ...,
                            title, 
                            "slug": slug.current,
                            _type,
                            externalLink{
                                ...,
                            }
                        }, 
                        venues[] {
                            ..., 
                            link->{
                                title, 
                                "slug":slug.current,
                                _type
                            }
                        },
                        ${
                            fetchEvents
                                ? `
                            _type == 'eventList' => {
                                "events": *[_type == 'event' && site == "${process.env.REACT_APP_SITE_NAME}"]{
                                ...,
                             } | order(startTime asc)
                            }
                        `
                                : ''
                        }
                    }
                },
                ${
                    getGlobals
                        ? `
                    "globals": ${globalsQuery}
                `
                        : ''
                }
            }`
        );
    };

    getSiteSettings = () => client.fetch(globalsQuery);

    getEvents = () =>
        client.fetch(
            `*[_type == 'event' && site == "${process.env.REACT_APP_SITE_NAME}"]`
        );

    getAll = () =>
        client.fetch(
            `*[_type == 'event' || _type == 'page' || _type == 'venue']`
        );
}
