import React from 'react';
import styled, { css } from 'styled-components';
import { colors } from '../../const';

const Container = styled.a`
    padding: 9px 24px 11px;
    border: 2px solid ${colors.gold};
    text-decoration: none !important;
    border-radius: 30px;
    cursor: pointer;

    &:hover {
        transform: scale(1.2);
        transition: all 0.3s ease;
    }

    ${props =>
        props.color &&
        css`
            border-color: ${props.color};
        `};

    color: white;
    display: inline-block;
`;

const RoundBorderButton = ({ link, children, ...rest }) => {
    return (
        <Container className="button" href={link} {...rest}>
            {children}
        </Container>
    );
};

export default RoundBorderButton;
