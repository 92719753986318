import React, { createRef, useRef } from 'react';
import styled from 'styled-components';

import { connect } from 'react-redux';
import moment from 'moment';
import EventListHeader from './eventList/EventListHeader';
import Event from './eventList/Event';
import { colors } from '../../const';
import { useInView } from 'react-hook-inview';
import CompletedEventListHeader from './eventList/CompletedEventListHeader';

const Container = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;

    .events {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        position: relative;
        padding-top: 100px;
        width: 100%;

        @media (max-width: 768px) {
            margin-top: -40px;
            padding-top: 100px;
        }

        > div {
            width: 50%;
            margin-bottom: 80px;

            @media (max-width: 768px) {
                width: 100%;
                margin-bottom: 50px;

                &:first-child {
                    margin-top: 0;
                }
            }
            &:nth-child(2n + 1) {
                padding-right: 100px;
                @media (max-width: 768px) {
                    padding-right: 0;
                    padding-left: 12vw;
                }
            }

            &:nth-child(2n + 2) {
                padding-left: 100px;
                margin-top: 100px;
                @media (max-width: 768px) {
                    margin-top: 0px;
                    padding-left: 12vw;
                }
            }
        }

        &:after {
            content: ' ';
            position: absolute;
            height: 100%;
            width: 1px;
            background-color: ${colors.greyBorder};
            left: 50%;
            top: 0;

            @media (max-width: 768px) {
                left: 0;
            }
        }
    }
    .finished {
        > div > * {
            opacity: 0.8;
            filter: grayscale(80%);
        }
        > div > *:hover {
            filter: unset;
            opacity: 1;
        }
    }
`;

const countEventsCompleted = events =>
    events.filter(event => moment(event.endTime).isBefore()).length;
const findUpcomingEvents = events =>
    events.filter(event => moment(event.endTime).isAfter());
const findCompletedEvents = events =>
    events.filter(event => moment(event.endTime).isBefore());
const countEventsComing = events => findUpcomingEvents(events).length;

const EventList = ({ events }) => {
    const [isVisible] = useInView({ unobserveOnEnter: true });

    const ref = React.createRef();

    return (
        <Container
            className={
                'module module-event-list ' + (isVisible ? 'in-view' : '')
            }
        >
            <EventListHeader
                completed={countEventsCompleted(events)}
                upcoming={countEventsComing(events)}
                target={ref}
            />
            <div className="events">
                {findUpcomingEvents(events).map(item => (
                    <Event event={item} key={item._id} />
                ))}
            </div>
            <CompletedEventListHeader
                completed={countEventsCompleted(events)}
                upcoming={countEventsComing(events)}
                ref={ref}
            />
            <div className="events finished">
                {findCompletedEvents(events).map(item => (
                    <Event finished event={item} key={item._id} />
                ))}
            </div>
        </Container>
    );
};

const mapStateToProps = (state, ownProps) => {
    return Object.assign(
        {},
        {
            events: state.events
        },
        ownProps
    );
};

export default connect(mapStateToProps, null)(EventList);
