import React from 'react';
import styled from 'styled-components';
import randomString from '../../utils/randomString';

const InstagramIcon = styled(props => {
    const clipId1 = randomString(4);
    const clipId2 = randomString(4);
    const clipId3 = randomString(4);

    return (
        <svg
            width="23.254"
            height="23.254"
            viewBox="0 0 23.254 23.254"
            {...props}
        >
            <defs>
                <clipPath id={clipId1}>
                    <path
                        id="Path_4"
                        d="M296-36.373A11.627,11.627,0,0,1,307.627-48a11.627,11.627,0,0,1,11.627,11.627,11.627,11.627,0,0,1-11.627,11.627A11.627,11.627,0,0,1,296-36.373Zm11.628-6.2c-1.684,0-1.9.007-2.557.037a4.55,4.55,0,0,0-1.505.288,3.036,3.036,0,0,0-1.1.715,3.044,3.044,0,0,0-.716,1.1,4.53,4.53,0,0,0-.288,1.505c-.03.661-.037.873-.037,2.557s.008,1.895.037,2.556a4.556,4.556,0,0,0,.288,1.505,3.038,3.038,0,0,0,.715,1.1,3.036,3.036,0,0,0,1.1.716,4.555,4.555,0,0,0,1.505.288c.661.03.873.037,2.557.037s1.895-.007,2.556-.037a4.558,4.558,0,0,0,1.506-.288,3.034,3.034,0,0,0,1.1-.716,3.043,3.043,0,0,0,.716-1.1,4.6,4.6,0,0,0,.288-1.505c.03-.661.037-.872.037-2.556s-.008-1.9-.037-2.557a4.594,4.594,0,0,0-.288-1.505,3.043,3.043,0,0,0-.716-1.1,3.031,3.031,0,0,0-1.1-.715,4.565,4.565,0,0,0-1.506-.288c-.661-.03-.872-.037-2.557-.037Z"
                        transform="translate(-296 48)"
                        fill="#fff"
                        clipRule="evenodd"
                    />
                </clipPath>
                <clipPath id={clipId2}>
                    <path
                        id="Path_6"
                        d="M314.035-34.495h.556c1.656,0,1.852.006,2.506.036a3.433,3.433,0,0,1,1.151.214,1.917,1.917,0,0,1,.713.464,1.925,1.925,0,0,1,.464.713,3.427,3.427,0,0,1,.214,1.151c.03.654.036.85.036,2.5s-.007,1.851-.036,2.5a3.43,3.43,0,0,1-.214,1.151,1.918,1.918,0,0,1-.464.712,1.917,1.917,0,0,1-.713.464,3.42,3.42,0,0,1-1.151.214c-.654.03-.85.036-2.506.036s-1.852-.006-2.506-.036a3.44,3.44,0,0,1-1.151-.214,1.92,1.92,0,0,1-.713-.464,1.921,1.921,0,0,1-.464-.713,3.425,3.425,0,0,1-.214-1.151c-.03-.654-.036-.85-.036-2.506s.006-1.851.036-2.5a3.432,3.432,0,0,1,.214-1.151,1.92,1.92,0,0,1,.464-.713,1.924,1.924,0,0,1,.713-.464,3.425,3.425,0,0,1,1.151-.214c.572-.026.794-.034,1.949-.035Zm3.866,1.03a.744.744,0,0,0-.744.744.744.744,0,0,0,.744.744.744.744,0,0,0,.744-.744.744.744,0,0,0-.744-.744Zm-3.31.869a3.185,3.185,0,0,0-3.185,3.185,3.184,3.184,0,0,0,3.185,3.184,3.184,3.184,0,0,0,3.184-3.184,3.184,3.184,0,0,0-3.184-3.185Z"
                        transform="translate(-309.508 34.497)"
                        fill="#fff"
                        clipRule="evenodd"
                    />
                </clipPath>
                <clipPath id={clipId3}>
                    <path
                        id="Path_8"
                        d="M317.8-28.267a2.067,2.067,0,0,1,2.067,2.067,2.067,2.067,0,0,1-2.067,2.067,2.067,2.067,0,0,1-2.067-2.067,2.067,2.067,0,0,1,2.067-2.067Z"
                        transform="translate(-315.734 28.267)"
                        fill="#fff"
                        clipRule="evenodd"
                    />
                </clipPath>
            </defs>
            <g id="Group_8" transform="translate(0 0)">
                <g id="Group_2" clipPath={`url(#${clipId1})`}>
                    <path
                        id="Path_3"
                        d="M291-53h28.1v28.1H291Z"
                        transform="translate(-293.422 50.578)"
                        fill="#fff"
                    />
                </g>
                <g
                    id="Group_3"
                    transform="translate(6.544 6.542)"
                    clipPath={`url(#${clipId2})`}
                >
                    <path
                        id="Path_5"
                        d="M304.508-39.5H319.52v15.01H304.508Z"
                        transform="translate(-306.93 37.074)"
                        fill="#fff"
                    />
                </g>
                <g
                    id="Group_4"
                    transform="translate(9.561 9.56)"
                    clipPath={`url(#${clipId3})`}
                >
                    <path
                        id="Path_7"
                        d="M310.734-33.267h8.979v8.979h-8.979Z"
                        transform="translate(-313.157 30.844)"
                        fill="#fff"
                    />
                </g>
            </g>
        </svg>
    );
})``;

export default InstagramIcon;
