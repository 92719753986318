import React from 'react';
import styled from 'styled-components';
import BlockContent from '@sanity/block-content-to-react';
import { colors } from '../../const';
import { usePath } from 'hookrouter';
import useAnchor from '../../hooks/useAnchor';
import blockSerializers from '../../utils/blockSerializers';
import useWindowSize from '../../hooks/useWindowSize';
import { useInView } from 'react-hook-inview';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 110px;

    @media (max-width: 768px) {
        flex-direction: column;
    }

    .title-column {
        width: 30%;
        margin-right: 20px;

        @media (max-width: 768px) {
            width: 100%;
            margin-right: 0;
            margin-bottom: 20px;
        }

        h2 {
            margin: 0;
            font-size: 36px;
            color: ${colors.pink}
            font-weight: 700;

            @media (max-width: 768px) {
                font-size: 22px;
            }
        }
    }

    .row {
        display: flex;
        flex-direction: row;
        width: 100%;
        flex-wrap: wrap;

        @media (max-width: 1231px) {
            justify-content: space-between;

            div:last-child {
                flex: 1;
            }
        }

        .column {
            margin-right: 20px;
            width: calc(33.33% - 20px);
            min-width: 260px;
            font-size: 18px;

            &:first-child {
                img {
                    margin-top: 16px;
                }
            }


            @media (max-width: 768px) {
                width: 100%;
                margin-right: 0;
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            h1,h2,h3 {
                font-size: 30px;
                font-weight: 700;
                margin-top: 6px;

                @media (max-width: 768px) {
                    font-size: 22px;
                    margin-top: 0px;
                }
            }
            p{
                max-width: 802px;
            }
            @media (max-width: 768px) {
                p {
                    font-size: 16px;
                }
            }
            div,
            iframe{
                max-width: calc(100vw - 25px);
            }
        }
    }

    .row.single-column {
        .column {
            width: 100%;
        }
    }

`;

const SideSection = ({ title, columns, slug }) => {
    const [ref, isVisible] = useInView({ unobserveOnEnter: true });
    return (
        <Container
            className={
                'module module-side-section ' + (isVisible ? 'in-view' : '')
            }
            id={slug ? slug.current : null}
            ref={ref}
        >
            <div className="title-column">{title && <h2>{title}</h2>}</div>
            <div
                className={`row ${
                    columns && columns.length === 1 ? 'single-column' : ''
                }`}
            >
                {columns &&
                    columns.map(column => (
                        <div className="column" key={column._key}>
                            <BlockContent
                                blocks={column.content}
                                projectId={
                                    process.env.REACT_APP_SANITY_PROJECT_ID
                                }
                                dataset={process.env.REACT_APP_SANITY_DATASET}
                                serializers={blockSerializers}
                            />
                        </div>
                    ))}
            </div>
        </Container>
    );
};

export default SideSection;
